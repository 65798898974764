

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      snackbarIcon: state => state.snackbarIcon,
      snackbarIconColor: state => state.snackbarIconColor,
      snackbarText: state => state.snackbarText,
      snackbarTimeout: state => state.snackbarTimeout
    })
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('CLOSE_SNACKBAR')
    }
  }
};
